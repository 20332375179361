import LocationOnIcon from "@mui/icons-material/LocationOn";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import { Box, Typography } from "@mui/material";
import { StyledListItemButton } from "components/_generics/StyledListItemButton";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { FaFolderOpen } from "react-icons/fa";
import { RiUserStarFill } from "react-icons/ri";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { HiBuildingOffice, HiUserGroup } from "react-icons/hi2";
import { VscScreenFull } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { checkIfEmpty } from "utils/helpers";
import { lang } from "utils/translations/globalLang";
import { FaUserShield } from "react-icons/fa";
const SidebarList = ({ spacingBetween = false }) => {
  const { pathname } = useLocation();
  const pathName = pathname.split("/")[1];

  const langType = useSelector((state) => state.lang.lang);
  const systemAdmin = localStorage.getItem("system_admin") === "true";
  const hasAccess =
    localStorage.getItem("hasAccess") === "true" &&
    localStorage.getItem("companySignupStatus") !== "SIGNUP_INCOMPLETE";
  const read = !checkIfEmpty(localStorage.getItem("read"))
    ? localStorage.getItem("read").split(", ")
    : [];

  return (
    <List
      sx={{
        height: spacingBetween ? "100%" : "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: spacingBetween ? "space-between" : "",
      }}
    >
      {/* UPPER SECTION  */}
      <Box>
        {/* <ListItem disablePadding={true}>
          <StyledListItemButton
            component={RouterLink}
            to={"/"}
            sx={{ borderRadius: 0 }}
            selected={pathname.includes("workplace")}
            onClick={(event) => handleListItemClick(event, "workplace")}
          >
            <ListItemIcon
              sx={{
                "&:hover": {
                  color: "primary",
                },
              }}
            >
              <PollIcon
                sx={{
                  "&.MuiSvgIcon-root": {
                    fontSize: "30px",
                  },
                }}
              />
            </ListItemIcon>
            <ListItemText
              disablePadding={true}
              primary={
                <Typography variant="h6" color={"text.secondary"}>
                  {lang[langType].dashboard}
                </Typography>
              }
            />
          </StyledListItemButton>
        </ListItem> */}
        {(hasAccess ||
          (!checkIfEmpty(read) &&
            read.some((item) => item === "Workplace"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/workplace"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("workplace")}
            >
              <ListItemIcon sx={{ "": 0 }}>
                <LocationOnIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      fontSize: "30px",
                    },
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].workplace}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )}
        {(hasAccess ||
          (!checkIfEmpty(read) &&
            read.some((item) => item === "Attendance"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/attendance"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("attendance")}
            >
              <ListItemIcon>
                <BsFillPersonVcardFill style={{ fontSize: "26px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].attendance}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )}

        {(hasAccess ||
          (!checkIfEmpty(read) && read.some((item) => item === "File"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/media"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("media")}
            >
              <ListItemIcon>
                <FaFolderOpen style={{ fontSize: "28px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].file}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )}
        {(hasAccess ||
          (!checkIfEmpty(read) &&
            read.some((item) => item === "KYTReport"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/report"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("report")}
            >
              <ListItemIcon>
                <VscScreenFull style={{ fontSize: "28px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].kytReport}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )}
      </Box>

      {/* LOWER SECTION  */}
      <Box>
        {(hasAccess ||
          (!checkIfEmpty(read) &&
            read.some((item) => item === "Employee"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/employee"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("employee")}
            >
              <ListItemIcon>
                <HiUserGroup style={{ fontSize: "26px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].employee}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )}

        {/* kyt access  */}
        {/* {(hasAccess ||
          (!checkIfEmpty(read) && read.some((item) => item === "Kyt"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/kyt"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("kyt")}
            >
              <ListItemIcon>
                <SettingsAccessibilityIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      fontSize: "30px",
                    },
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].kytAccess}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )} */}

        {/* kyt questions */}
        {(hasAccess ||
          (!checkIfEmpty(read) && read.some((item) => item === "Kyt"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/kyt/questions"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("kyt")}
            >
              <ListItemIcon>
                <SettingsAccessibilityIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      fontSize: "30px",
                    },
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].kytUserQuestion}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )}
        {/* USER SUBSCRIPTIONS  */}
        {/* {(hasAccess ||
          (!checkIfEmpty(read) &&
            read.some((item) => item === "Subscription"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/subscriptions"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("subscriptions")}
            >
              <ListItemIcon>
                <FaFileInvoiceDollar style={{ fontSize: "24px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].subscription}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )} */}

        {/* USER PERMISSIONS  */}
        {(hasAccess ||
          (!checkIfEmpty(read) &&
            read.some((item) => item === "Permission"))) && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/permissions"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("permissions")}
            >
              <ListItemIcon>
                <ManageAccountsIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      fontSize: "30px",
                    },
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].permission}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )}
        {systemAdmin && (
          <ListItem disablePadding={true}>
            <StyledListItemButton
              component={RouterLink}
              to={"/company"}
              sx={{ borderRadius: 0 }}
              selected={pathName.includes("company")}
            >
              <ListItemIcon>
                <HiBuildingOffice style={{ fontSize: "28px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    color={"text.secondary"}
                  >
                    {lang[langType].company}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        )}
      </Box>
    </List>
  );
};

export default SidebarList;
